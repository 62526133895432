/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty food značky Master Magic"}>
        <Column className="pb--60 pt--60" name={"bemy3xtuuy"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Food produkty Master Magic"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/10edd6843f2d4a65b9e1437d936d5977_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/10edd6843f2d4a65b9e1437d936d5977_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/10edd6843f2d4a65b9e1437d936d5977_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/10edd6843f2d4a65b9e1437d936d5977_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/10edd6843f2d4a65b9e1437d936d5977_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l9"} name={"q6rnumivmwn"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":967}}>
              
              <Image style={{"maxWidth":512}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/f3fdaa0c9a2f45e0a1bdcd1359fa1f70_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/f3fdaa0c9a2f45e0a1bdcd1359fa1f70_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"<span style=\"font-weight: bold;\">Peanut spread 400 g no palm oil&nbsp; (Arašídová pomazánka 400 g bez palmového oleje.)</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --right el--1 mt--60 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":967}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":692}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"[[UNIgenericsection2]]"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}} name={"[[UNIgenericsecname3]]"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}